@use '../../assets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: var(--margin-medium-2);
  text-align: center;

  .actionBar {
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    padding: var(--padding-medium-2);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    align-items: center;
    gap: var(--margin-small-4);
    flex-direction: column;
    @include mixins.respond-to-min-width('medium') {
      flex-direction: row;
    }
    .text {
      font-weight: var(--font-weight-semibold);
      text-align: start;
      flex: 1;
    }
    .buttons {
      display: flex;
      gap: var(--padding-small-4);
      justify-content: flex-end;
      flex-direction: column-reverse;
      @include mixins.respond-to-min-width('medium') {
        flex-direction: row;
      }
    }
  }
}
