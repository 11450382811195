@use '../../assets/mixins.scss';

.subHeader {
  color: var(--dark-200);
  margin-bottom: var(--margin-medium-3);
}

.acceptedButtons {
  display: flex;
  justify-content: center;
  gap: var(--margin-small-4);
  margin: var(--margin-medium-3);
  align-items: center;

  .link.link {
    color: var(--dark-500);
    background-color: transparent;
    border: none;
    span {
      font-family: var(--font-family-content);
      font-size: var(--font-medium-1);
    }
    .chevron {
      transform: rotate(90deg);
    }
  }
}

.container {
  padding: var(--padding-small-4);
  @include mixins.respond-to-min-width('medium') {
    padding: 0;
  }
}

.title {
  text-align: center;
  @include mixins.respond-to-min-width('medium') {
    text-align: start;
  }
}
