@use '../../assets/mixins.scss';

.container {
  padding: var(--padding-medium-3);
  background-color: var(--light-300);
  margin-top: auto;
  .content {
    margin: auto;
    display: grid;
    align-items: flex-start;
    max-width: 1100px;
    row-gap: var(--margin-small-3);
    @include mixins.respond-to('large') {
      max-width: 720px;
    }
    .trustpilot {
      max-height: 120px;
    }
  }
}

.orgInfo {
  display: block;
  text-align: center;
  font-size: var(--font-small-1);
  color: var(--dark-200);
}

.contactInfo {
  display: flex;
  gap: var(--margin-small-1);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mixins.respond-to-min-width('medium') {
    flex-direction: row;
  }
  .detail {
    display: flex;
    gap: var(--margin-small-2);
    padding: var(--padding-small-1) var(--padding-small-4);
    align-items: center;
    line-height: var(--line-height-content);
    font-weight: var(--font-weight-semibold);
    @include mixins.respond-to-min-width('medium') {
      &:first-child {
        border-right: 1px solid var(--dark-200);
      }
    }
  }
}
