@use '../../assets/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  color: var(--dark-200);
  margin-bottom: var(--margin-medium-2);
}

.infoBar {
  background-color: var(--white-100);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: var(--padding-small-4);
  display: flex;
  font-size: var(--font-small-3);
  flex-direction: column;
  text-align: center;
  gap: var(--margin-small-3);
  &.highlighted {
    background-color: var(--success-100);
  }
  @include mixins.respond-to-min-width('small') {
    text-align: start;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--margin-medium-2);
    .idText {
      text-align: end;
    }
  }
}

.statusBanner {
  z-index: -1;
  background-color: var(--light-300);
  padding: 5px var(--padding-small-4);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-radius: 0 0 var(--border-radius) var(--border-radius);

  @include mixins.respond-to-min-width('small') {
    text-align: right;
  }
}

.infoText {
  font-weight: var(--font-weight-bold);
  .highlightedText {
    color: var(--primary-300);
  }
}
