@use '../../assets/mixins.scss';

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: var(--margin-small-4);
  color: var(--dark-900);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: var(--margin-medium-3) 0;
  @include mixins.respond-to-min-width('medium') {
    margin: var(--margin-medium-3);
    .loadingText {
      max-width: 515px;
      padding: 0 var(--padding-medium-2);
    }
  }
}
