@import '../../assets/mixins.scss';

.contentBox.contentBox {
  padding: var(--padding-small-4);
  background-color: white;
  border-width: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin-top: var(--margin-medium-3);
  @include respond-to-min-width('large') {
    padding: var(--padding-small-4) var(--padding-small-3);
  }
}

.recommendedTag.recommendedTag {
  letter-spacing: 2px;
  font-size: var(--font-small-2);
  text-transform: uppercase;
  justify-content: center;
  gap: var(--padding-small-3);
  top: -14px;
  padding-left: var(--padding-medium-2);
  padding-right: var(--padding-medium-2);
}

.detailsContainer {
  display: flex;
  gap: var(--margin-medium-2);
  padding: var(--padding-small-2) 0 0 0;
  flex-direction: column;
  @include respond-to-min-width('large') {
    gap: var(--margin-large-1);
    flex-direction: row;
    padding: 0;
  }
}

.lenderLogo {
  max-width: 100%;
  max-height: 42px;
  display: inline-block;
  @include respond-to-min-width('large') {
    max-height: 72px;
  }
}

.lenderLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 140px;
  @include respond-to-min-width('large') {
    padding-left: var(--padding-small-4);
  }
}

.detailsGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: var(--margin-small-1);
  padding: 0;
  @include respond-to-min-width('medium') {
    flex-direction: row;
    gap: 0;
  }
  @include respond-to-min-width('large') {
    padding: var(--padding-medium-2) 0;
  }
  .detailItem {
    display: flex;
    justify-content: space-between;
    @include respond-to-min-width('medium') {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      gap: var(--margin-small-2);
    }
    .label {
      color: var(--dark-200);
    }
    .value {
      color: var(--dark-900);
      font-weight: var(--font-weight-semibold);
      @include respond-to-min-width('medium') {
        font-size: var(--font-medium-1);
      }
    }
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: var(--margin-small-2);
  .button.button {
    padding: var(--padding-small-2) var(--padding-small-4);
    width: 100%;
    line-height: var(--line-height-content);
    font-size: var(--font-medium-1);
    @include respond-to-min-width('large') {
      width: auto;
    }
    &.actionButton {
      min-width: 7.5rem;
    }
    &.black {
      background-color: var(--dark-500);
      color: white;
      border-color: var(--dark-500);
    }
  }
}

.caretFlipped.caretFlipped {
  transform: rotate(180deg);
}

.detailBanner {
  display: flex;
  flex-direction: column;
  background-color: var(--light-300);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: var(--padding-small-4);
  gap: var(--margin-small-4);
  margin-top: -5px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  @include respond-to-min-width('medium') {
    flex-direction: row;
    gap: var(--margin-medium-4);
  }
  .title {
    white-space: nowrap;
    font-weight: var(--font-weight-semibold);
  }
  .info {
    display: flex;
    gap: var(--margin-small-3);
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    .icon {
      margin-top: 2px;
    }
  }
}
