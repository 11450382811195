@use '../../assets/mixins.scss';

.modalRoot.modalRoot {
  align-items: flex-end;
  @include mixins.respond-to-min-width('medium') {
    align-items: center;
  }
}

.modal {
  width: 100%;
  margin: 0;
  @include mixins.respond-to-min-width('medium') {
    width: auto;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--margin-small-4);
  margin-top: var(--margin-small-4);

  .subtitle {
    color: var(--light-900);
  }

  .lenders {
    display: flex;
    align-items: center;
    gap: var(--margin-small-4);
    padding: var(--padding-small-4) var(--padding-medium-2);
    flex-direction: column;

    @include mixins.respond-to-min-width('medium') {
      flex-direction: row;
    }

    .lenderLogoContainer {
      padding: 0 var(--padding-medium-2);
      width: 10rem;
      display: flex;
      flex-direction: column;
      gap: var(--margin-small-3);
      color: var(--dark-200);
      font-size: var(--font-small-1);
      letter-spacing: 1.2px;
      font-weight: var(--font-weight-bold);

      .lenderLogo {
        padding: var(--padding-small-4);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--border-radius);
      }
    }

    .arrow {
      transform: rotate(90deg);
      @include mixins.respond-to-min-width('medium') {
        transform: rotate(0deg);
      }
    }
  }

  .actionContainer {
    padding-top: var(--padding-small-4);
    border-top: 1px solid var(--light-600);
    font-weight: var(--font-weight-bold);
    .buttons {
      display: flex;
      justify-content: center;
      gap: var(--margin-medium-2);
      margin-top: var(--margin-small-4);
      flex-direction: column;
      width: 100%;
      @include mixins.respond-to-min-width('medium') {
        flex-direction: row;
      }
    }
  }

  .bold {
    font-weight: 600;
  }
}
