@use '../../assets/mixins.scss';

.modalRoot.modalRoot {
  align-items: flex-end;
  @include mixins.respond-to-min-width('medium') {
    align-items: center;
  }
}

.modal {
  @include mixins.respond-to-min-width('mobile') {
    width: auto;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--margin-small-4);
  margin-top: var(--margin-small-4);

  .subtitle {
    color: var(--light-900);
  }

  .lenderLogoContainer {
    padding: var(--padding-medium-2);
    width: 12rem;
  }

  .actionContainer {
    padding-top: var(--padding-small-4);
    border-top: 1px solid var(--light-600);
    .buttons {
      display: flex;
      justify-content: center;
      gap: var(--margin-medium-2);
      margin-top: var(--margin-small-4);
      flex-direction: column;
      width: 100%;
      @include mixins.respond-to-min-width('mobile') {
        flex-direction: row;
      }
    }
  }

  .bold {
    font-weight: 600;
  }
}
