@use '../../assets/mixins.scss';

.container {
  margin: 0 auto;
  max-width: 1100px;
  padding: var(--padding-small-2) 0 var(--padding-large-1) 0;
  width: 100%;
  @include mixins.respond-to('large') {
    max-width: 720px;
  }
}
