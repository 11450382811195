$breakpoints: (
  'small': 480,
  'mobile': 576,
  'medium': 768,
  'large': 1200,
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint) + 'px') {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respond-to-min-width($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint) + 'px') {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin make-section-structure() {
  display: grid;
  grid-template-columns: 35fr 65fr;
  max-width: 1100px;
  margin: 0 auto;
  padding: var(--padding-medium-3) 0;

  @include make-breakpoints-width();

  @include respond-to('medium') {
    display: flex;
    flex-direction: column;
    row-gap: var(--margin-small-2);
  }

  @include respond-to('mobile') {
    padding: var(--padding-medium-3) 0;
  }
}

@mixin make-breakpoints-width() {
  max-width: 1100px;

  @include respond-to('large') {
    max-width: 720px;
  }

  @include respond-to('medium') {
    max-width: 540px;
  }

  @include respond-to('mobile') {
    max-width: 100%;
  }
}

@mixin make-cta-button-structure() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-family: var(--font-family-heading);

  @include respond-to('medium') {
    width: 100%;
  }

  svg {
    width: 15px;
    height: 15px;
  }
}
