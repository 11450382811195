:root {
  --primary-100: #fff9e6;
  --primary-rgb-100: 255, 249, 230;
  --primary-200: #ffe187;
  --primary-rgb-200: 255, 225, 135;
  --primary-300: #ffdb6d;
  --primary-rgb-300: 255, 219, 109;
  --primary-500: #ffc107;
  --primary-rgb-500: 255, 193, 7;
  --primary-700: #ba8b00;
  --primary-rgb-700: 186, 139, 0;
  --primary-900: #876500;
  --primary-rgb-900: 135, 101, 0;

  --secondary-100: #fdeee9;
  --secondary-rgb-100: 253, 238, 233;
  --secondary-200: #f3aa91;
  --secondary-rgb-200: 243, 170, 145;
  --secondary-300: #f1987a;
  --secondary-rgb-300: 241, 152, 122;
  --secondary-500: #e7511e;
  --secondary-rgb-500: 231, 81, 30;
  --secondary-700: #a73812;
  --secondary-rgb-700: 167, 56, 18;
  --secondary-900: #79280d;
  --secondary-rgb-900: 121, 40, 13;

  --success-100: #e5f8f4;
  --success-rgb-100: 229, 248, 244;
  --success-200: #80dbca;
  --success-rgb-200: 128, 219, 202;
  --success-300: #00caa6;
  --success-rgb-300: 0, 202, 166;
  --success-500: #00b695;
  --success-rgb-500: 0, 182, 149;
  --success-700: #009277;
  --success-rgb-700: 0, 146, 119;
  --success-900: #006d59;
  --success-rgb-900: 0, 109, 89;

  /* TO-BE CHECKED/REMOVED */
  --green-300: #86BD47;
  --green-rgb-300: 134, 189, 71;

  --danger-100: #fdeee9;
  --danger-rgb-100: 253, 238, 233;
  --danger-200: #f3aa91;
  --danger-rgb-200: 243, 170, 145;
  --danger-300: #f1987a;
  --danger-rgb-300: 241, 152, 122;
  --danger-500: #e7511e;
  --danger-rgb-500: 231, 81, 30;
  --danger-700: #a73812;
  --danger-rgb-700: 167, 56, 18;
  --danger-900: #79280d;
  --danger-rgb-900: 121, 40, 13;

  --warning-100: #fff9e6;
  --warning-rgb-100: 255, 249, 230;
  --warning-200: #ffe187;
  --warning-rgb-200: 255, 225, 135;
  --warning-300: #ffdb6d;
  --warning-rgb-300: 255, 219, 109;
  --warning-500: #ffc107;
  --warning-rgb-500: 255, 193, 7;
  --warning-700: #ba8b00;
  --warning-rgb-700: 186, 139, 0;
  --warning-900: #876500;
  --warning-rgb-900: 135, 101, 0;

  --info-100: #eef6ff;
  --info-rgb-100: 238, 246, 255;
  --info-200: #bad9fd;
  --info-rgb-200: 186, 217, 253;
  --info-300: #98c7fc;
  --info-rgb-300: 152, 199, 252;
  --info-500: #53a1fa;
  --info-rgb-500: 83, 161, 250;
  --info-700: #4281c8;
  --info-rgb-700: 66, 129, 200;
  --info-900: #326196;
  --info-rgb-900: 50, 97, 150;

  --light-100: #fcfcfc;
  --light-rgb-100: 252, 252, 252;
  --light-200: #f8f8f8;
  --light-rgb-200: 248, 248, 248;
  --light-300: #f4f4f4;
  --light-rgb-300: 244, 244, 244;
  --light-400: #e3e3e3;
  --light-rgb-400: 227, 227, 227;
  --light-500: #cfcfcf;
  --light-rgb-500: 207, 207, 207;
  --light-600: #b5b5b5;
  --light-rgb-600: 181, 181, 181;
  --light-700: #9c9c9c;
  --light-rgb-700: 156, 156, 156;
  --light-900: #848484;
  --light-rgb-900: 132, 132, 132;

  --grey-100: white;
  --grey-rgb-100: 255, 255, 255;

  --dark-100: #828d98;
  --dark-rgb-100: 130, 141, 152;
  --dark-200: #5a6570;
  --dark-rgb-200: 90, 101, 112;
  --dark-300: #4e5862;
  --dark-rgb-300: 78, 88, 98;
  --dark-500: #363d43;
  --dark-rgb-500: 54, 61, 67;
  --dark-700: #212529;
  --dark-rgb-700: 33, 37, 41;
  --dark-900: black;
  --dark-rgb-900: 0, 0, 0;

  --white-100: white;
  --white-rgb-100: 255, 255, 255;
  --white-200: white;
  --white-rgb-200: 255, 255, 255;
  --white-300: var(--light-300);
  --white-rgb-300: var(--light-rgb-300);
  --white-500: var(--light-500);
  --white-rgb-500: var(--light-rgb-500);
  --white-700: var(--light-700);
  --white-rgb-700: var(--light-rgb-700);
  --white-900: var(--light-900);
  --white-rgb-900: var(--light-rgb-900);

  /* Aliases */
  --primary-color: var(--primary-500);
  --secondary-color: var(--secondary-500);
  --black-color: var(--dark-900);
  --text-color: var(--dark-900);
  --white-color: var(--white-100);
  --success-color: var(--success-500);
  --danger-color: var(--danger-500);
  --danger-light-color: var(--danger-100);
  --info-color: var(--info-500);

  /* BORDERS */
  --border-radius: 5px;

  /* INPUT FIELDS ELEVATION */
  --box-shadow-base: 0 0 0 2px;
  --box-shadow: 0 0 0 2px rgba(171, 171, 171, 0.2);
  --box-shadow-danger: 0 0 0 2px rgba(167, 56, 18, 0.1);
  --box-shadow-success: 0 0 0 2px rgba(var(--success-rgb-500), 0.3);

  /* ELEVATION */
  --bottom-container-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  --bottom-container-spread: 0 0 10px 0 rgba(0,0,0,.1);

  /* PADDING */
  --padding-small-1: 4px;
  --padding-small-2: 8px;
  --padding-small-3: 12px;
  --padding-small-4: 16px;
  --padding-medium-1: 20px;
  --padding-medium-2: 24px;
  --padding-medium-3: 32px;
  --padding-medium-4: 40px;
  --padding-large-1: 48px;
  --padding-large-2: 64px;
  --padding-large-3: 80px;

  /* MARGIN */
  --margin-small-1: 4px;
  --margin-small-2: 8px;
  --margin-small-3: 12px;
  --margin-small-4: 16px;
  --margin-medium-1: 20px;
  --margin-medium-2: 24px;
  --margin-medium-3: 32px;
  --margin-medium-4: 40px;
  --margin-large-1: 48px;
  --margin-large-2: 64px;
  --margin-large-3: 80px;

  /* FONT SIZES */
  --font-small-1: 12px;
  --font-small-2: 14px;
  --font-small-3: 16px;
  --font-medium-1: 18px;
  --font-medium-2: 20px;
  --font-medium-3: 24px;
  --font-medium-4: 32px;
  --font-large-1: 40px;
  --font-large-2: 48px;
  --font-large-3: 60px;

  /* CTAS FONT SIZE */
  --cta-button-font-size: 20px;

  /* FONT WEIGHTS */
  --font-weight-normal: normal;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* FONTS */
  --font-family-heading: 'Heading-font', Georgia, Times, serif;
  --font-family-content: 'Content-font', Arial, Helvetica, Sans-Serif;

  /* LINE HEIGHTS */
  --line-height-heading: 125%;
  --line-height-content: 150%;

  /* BREAKPOINTS */
  --breakpoint-small: 480px;
  --breakpoint-mobile: 576px;
  --breakpoint-medium: 768px;
  --breakpoint-large: 1200px;
}

@font-face {
  font-family: 'Heading-font';
  src: url('./Poppins-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Content-font';
  src: url('./sourcesanspro-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Content-font';
  src: url('./sourcesanspro-semibold-webfont.woff2');
  ;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Content-font';
  src: url('./sourcesanspro-bold-webfont.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

p, p *, span, span *, ul, ul * {
  font-size: var(--font-small-3);
  font-family: var(--font-family-content);
}

h1,
h1 *,
h2,
h2 *,
h3,
h3 *,
h4,
h4 *,
h5,
h5 *,
h6,
h6 * {
  color: inherit;
  font-family: var(--font-family-heading);
  margin: 0;
}

h1 {
  font-size: var(--font-large-1);
  margin-top: -10px;
}

h2 {
  font-size: var(--font-medium-4);
  margin-top: -8px;
}

h3 {
  font-size: var(--font-medium-3);
  margin-top: -5px;
}

h4 {
  font-size: var(--font-medium-1);
  margin-top: -3px;
}

h5 {
  font-size: var(--font-small-3);
}

h6 {
  font-size: var(--font-small-3);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--text-color);
}

@media screen and (max-width: 576px) {
  h1, h2 {
    font-size: var(--font-medium-3);
  }
}
